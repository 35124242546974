import * as React from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, map } from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import {
  NavHeader,
  NavMenu,
  ValueHighlight,
  UnlockConfirmation,
  ActionButton,
  Pagination,
} from '../../components'
import {
  getRacks as getApiRacks,
  getStats as getApiStats,
  unlockRack,
  forceLockRack,
  forceUnlockRack,
  switchToMode,
  openNotifModal,
} from '../../actions'
import { updateRack } from '../../api'
import { RACK_PER_PAGE, RACK_ALIVE_TIMEOUT, FEATURE_RACK_ALIVE_TIMEOUT } from '../../constants'

import './styles.scss'

class Racks extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpened: false,
      rackId: 0,
      rackComment: '',
      isRackUpdating: false,
      totalPages: Math.ceil(props.racks.length / RACK_PER_PAGE),
      currentPage: 1,
    }
  }

  componentDidMount() {
    this.loadStuff()
  }

  componentDidUpdate(prevProps) {
    const { rackUnlocked } = this.props

    if (!prevProps.rackUnlocked && rackUnlocked) {
      this.setState({ isOpened: true })
    }
  }

  componentWillUnmount() {
    clearTimeout()
  }

  onChangeComment(rackId, text) {
    this.setState({ [rackId]: text })
  }

  loadStuff() {
    const { getStats, getRacks } = this.props

    getRacks()
    getStats()

    setTimeout(() => this.loadStuff(), 10000)
  }

  renderHighlights() {
    const {
      stats: { totalRentals, totalRentalsToday, liveRacks, rentalsOnGoing, emptyLiveRacks },
    } = this.props

    return (
      <div className="highlight-container">
        <ValueHighlight label="racks en service" value={liveRacks} />
        <ValueHighlight label="locations au total" value={totalRentals} />
        {/* <ValueHighlight label="trottinettes disponibles" value={liveRacks - emptyLiveRacks} /> */}
        <ValueHighlight label="locations aujourd’hui" value={totalRentalsToday} />
        <ValueHighlight label="locations en cours" value={rentalsOnGoing} />
      </div>
    )
  }

  renderUnlockButton(id, name, mode, live, hasScooter) {
    // 3 states
    // hasScooter && !isUnlocked => locked with a scooter inside
    // !hasScooter => unlocked with no scooter
    // hasScooter && isUnlocked => unlocked with scooter inside (rental in progress)
    const { unlockRack, forceLockRack, forceUnlockRack, isMasterAdmin, switchToMode } = this.props

    const unlocking = this.state[id]

    const className = !hasScooter || unlocking ? 'button-disabled button-overflow' : 'button-black'
    let label = null

    if (unlocking) {
      label = 'Déverrouillage...'
    } else if (hasScooter) {
      label = 'Déverrouiller'
    } else {
      label = 'Pas de trottinette'
    }

    const onClick =
      !hasScooter || unlocking
        ? null
        : () => {
            this.setState({ [id]: true })
            unlockRack(id)

            setTimeout(() => this.setState({ [id]: false }), 3000)
          }

    return (
      live && (
        <div>
          <ActionButton
            className={`${className} icon-reverse xsmall-margin`}
            label={label}
            onClick={onClick}
            icon="/assets/images/lock_outlined.png"
          />
          {isMasterAdmin && (
            <>
              <ActionButton
                className="button-block button-overflow xsmall-margin button-black button-mode-manual"
                label={'M'}
                icon=""
                onClick={() => {
                  switchToMode(id, 'manual')
                }}
                disabled={mode === 'manual'}
              />
              <ActionButton
                className="button-block button-overflow xsmall-margin button-black button-mode-auto"
                label={'A'}
                icon=""
                onClick={() => {
                  switchToMode(id, 'auto')
                }}
                disabled={mode === 'auto'}
              />
              <ActionButton
                className="button-block button-overflow xsmall-margin button-black"
                label={''}
                icon="/assets/images/force_unlock.png"
                onClick={() => {
                  forceUnlockRack(id)
                }}
              />
              <ActionButton
                className="button-block button-overflow xsmall-margin button-black"
                icon="/assets/images/force_lock.png"
                onClick={() => {
                  forceLockRack(id)
                }}
              />
            </>
          )}
        </div>
      )
    )
  }

  isRackLive(live) {
    if (live) {
      if (FEATURE_RACK_ALIVE_TIMEOUT) {
        const now = moment()
        const aliveAt = moment(live.aliveAt)
        const difference = moment.duration(now.diff(aliveAt))
        const differenceMinutes = difference.asMinutes()

        return differenceMinutes <= RACK_ALIVE_TIMEOUT
      }

      return true
    }

    return false
  }

  renderTable() {
    const { racks } = this.props

    const { totalPages, currentPage } = this.state
    const paginatedRack = racks.slice
      ? racks.slice((currentPage - 1) * RACK_PER_PAGE, currentPage * RACK_PER_PAGE)
      : []

    return (
      <div className="table-bloc">
        <table>
          <thead>
            <tr>
              <th colSpan="3">Rack</th>
              <th>Engin</th>
              <th>Commentaire</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {map(paginatedRack, ({ id, uid, name, live, comment }, index) => {
              const isLive = this.isRackLive(live)

              return (
                <tr key={`item${uid}-${index}`}>
                  <td style={{ width: '20px' }}>
                    <div
                      className={classnames('status-badge', {
                        green: isLive,
                        'mode-manual': live && live.mode === 'manual',
                        'mode-auto': live && live.mode === 'auto',
                      })}
                    />
                  </td>
                  <td style={{ width: '40px' }}>{name}</td>
                  <td style={{ width: '180px' }}>{uid}</td>
                  <td style={{ width: '180px' }}>{get(live, 'detectedScooterId')}</td>
                  <td>{this.renderCommentBlock(id, comment)}</td>
                  <td className="table-button" style={{ width: '350px' }}>
                    {this.renderUnlockButton(
                      uid,
                      name,
                      live && live.mode,
                      isLive,
                      !!get(live, 'detectedScooterId'),
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onSelectPage={id => this.setState({ currentPage: id })}
        />
      </div>
    )
  }

  renderCommentBlock(uid, comment) {
    const onCancel = () => {
      this.setState({
        isRackUpdating: false,
        rackId: '',
        rackComment: '',
      })
    }

    const onSubmit = e => {
      e.preventDefault()

      this.setState({ isRackUpdating: true })
      updateRack(
        uid,
        { rack: { comment: this.state.rackComment } },
        () => {
          this.props.getRacks()
          onCancel()
          this.props.notif(
            'success',
            'Données mises à jour',
            'Le rack a été mis à jour avec succès !',
          )
        },
        err => {
          this.props.notif(
            'error',
            'Erreur',
            "Une erreur est survenue lors de l'enregistrement du commentaire",
          )
          console.error(err)
          onCancel()
        },
      )
    }

    const selectRack = () => {
      !this.state.isRackUpdating && this.setState({ rackComment: comment, rackId: uid })
    }

    if (uid !== this.state.rackId)
      return (
        <div className="comment-block">
          <span>{comment}</span>
          {!this.state.isRackUpdating && (
            <button type="button" onClick={() => selectRack()}>
              <img src="/assets/images/edit.svg" />
            </button>
          )}
        </div>
      )

    return (
      <form className="edit-comment-block" onSubmit={e => onSubmit(e)}>
        <input
          onChange={e => this.setState({ rackComment: e.target.value })}
          value={this.state.rackComment || ''}
        />
        {!this.state.isRackUpdating && (
          <>
            <button type="button" onClick={e => onSubmit(e)}>
              <img src="/assets/images/arrow-right.png" />
            </button>
            <button type="button" onClick={() => onCancel()}>
              <img src="/assets/images/clear.png" />
            </button>
          </>
        )}
      </form>
    )
  }

  render() {
    const { isLoading, racks, isAdmin } = this.props
    const { isOpened } = this.state

    return (
      <div className="board-container">
        <NavHeader pageTitle={'Mes racks'} />
        <NavMenu />
        <div className={`table-container ${isAdmin && 'with-menu'}`}>
          {this.renderHighlights()}
          {isLoading && isEmpty(racks) ? 'Chargement...' : this.renderTable()}
        </div>
        <UnlockConfirmation
          isOpened={isOpened}
          onClose={() => this.setState({ isOpened: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ user: { isAdmin, isManager, isMasterAdmin }, racks, stats }) => ({
  isAdmin,
  isManager,
  isMasterAdmin,
  stats,
  racks: racks.allRacks,
  isLoading: racks.isLoading,
})

const mapDispatchToProps = dispatch => ({
  getRacks: () => dispatch(getApiRacks()),
  getStats: () => dispatch(getApiStats()),
  unlockRack: id => dispatch(unlockRack(id)),
  forceLockRack: id => dispatch(forceLockRack(id)),
  forceUnlockRack: id => dispatch(forceUnlockRack(id)),
  switchToMode: (rackId, mode) => dispatch(switchToMode(rackId, mode)),
  notif: (type, title, message) => dispatch(openNotifModal(type, title, message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Racks)
